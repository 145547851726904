@import "./common";

.red {
  color: $main-red;
}

.emoji {
  font-family: Tossface, sans-serif;
}

.hidden {
  display: none;
}
