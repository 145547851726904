@import "../../../styles/common";

.notice-aside {
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;

  &.active {
    position: fixed;
    opacity: 1;
    left: 0;
  }

  width: 25vw;
  height: 100vh;
  display: inline-flex;
  flex-direction: column;
  background-color: $light-grey;
  border-right: 1px solid $grey;
  padding: 86px 30px 20px 30px;

  .notice-aside--close-button-wrapper {
    text-align: right;
    margin-bottom: 10px;

    .notice-aside--close-button {
      background-color: transparent;
      width: 30px;
      height: 30px;
    }
  }

  .notice-aside--notice-area {
    display: flex;
    justify-content: space-between;
    height: 100px;
    margin-bottom: 20px;

    .notice-aside--notice-area-textarea {
      width: 73%;
      height: 100%;
    }

    .notice-aside--notice-area-button {
      width: 75px;
      height: 100%;
      padding: 0 2%;

      span {
        font-size: 25px;
        font-weight: 500;
      }
    }
  }

  .notice-aside--notice {
    width: 100%;
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;

    .notice-aside--notice-no {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      color: $medium-grey;
    }
  }
}

.notice-aside-open {
  display: none;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translate(0%, -50%);
  padding: 50px 2.5px;
  background-color: $light-grey;
  border: 1px solid $grey;
  border-radius: 0 30px 30px 0;
  z-index: 2;

  img {
    width: 25px;
    height: 25px;
  }

  &.active {
    display: block;
  }
}

@media screen and (max-width: 1160px) {
  .notice-aside.responsive {
    width: 35vw;
  }
}

@media screen and (max-width: 600px) {
  .notice-aside.responsive {
    width: 100vw;
  }
}