@import "../../styles/common";

.record {
  width: 80vw;
  min-width: 350px;
  margin: 0 auto;
  padding: 80px 10px;

  .record-application-section {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .record-body {
    display: flex;
    justify-content: space-between;
  }

  .none {
    color: $dark-grey;
    text-align: center;
    padding-top: 120px;
  }
}

@media screen and (max-width: 700px) {
  .record {
    .teacher-application {
      .application-title {
        width: 60%;

        .application-title-icon {
          font-size: 25px;
        }

        .application-title-title {
          font-size: 20px;
        }
      }

      .application-comment {
        display: none;
      }

      .application-end-date {
        width: 40%;
        font-size: 15px;
      }
    }
  }
}
