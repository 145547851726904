@import "../../styles/common";

header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 100vw;
  height: 76px;
  z-index: 100;
  background-color: white;

  .header-inner {
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.mobile {
      display: none;
    }

    .header-logo {
      -webkit-tap-highlight-color: transparent;
      width: 85px;
    }

    .header-category-ul {
      .header-nav-li {
        padding: 0 20px;

        a {
          -webkit-tap-highlight-color: transparent;
          padding: 6px 15px;
          font-size: 18px;
          font-weight: 500;
          border-radius: 10px;

          &:hover {
            cursor: pointer;
            background-color: $light-grey;
          }
        }
      }
    }

    .search-area {
      padding-left: 6px;
      width: 20vw;
    }

    .header_login_button {
      -webkit-tap-highlight-color: transparent;
      position: relative;

      .login-button {
        color: black;
        font-size: 18px;
        font-weight: 500;
        padding: 5px 18px;
        border-radius: 12px;
        border-color: transparent;
        background-color: transparent;

        &:hover {
          background-color: $light-grey;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .search-area {
    display: none !important;
  }

  .header-category-ul .header-nav-li {
    padding: 0 0 !important;
  }
}

@media screen and (max-width: 700px) {
  .search-area {
    display: none;
  }

  body {
    padding-top: 65px;
  }

  header {
    height: 65px;

    .header-inner {
      &.desktop {
        display: none;
      }

      &.mobile {
        display: flex !important;
      }

      button {
        width: 40px;
        height: 40px;

        .button-image {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
