@import "../../../styles/common";

.toggle {
  display: inline-flex;
  align-items: center;
  color: $medium-grey;
  font-size: 14px;
  cursor: pointer;

  .toggle-container {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 30px;
    border-radius: 27.5px;
    background-color: $main-red;
    margin-right: 4px;
    padding: 0 7px;
    transition: 0.5s;

    &.disabled {
      background-color: $grey;

      .toggle-circle {
        left: 4px;
        transition: 0.5s;
      }
    }

    .toggle-circle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 4px;

      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: white;
      transition: 0.5s;
    }
  }
}
