@import "../../../styles/common";

.side-bar {
  width: 60vw;
  height: calc(var(--vh, 1vh) * 100);

  position: absolute;
  top: 0;
  left: 0;
  z-index: 102;
  background-color: white;
  animation: slide 250ms ease-in-out;

  &.entering {
    animation: slide-in 250ms ease-in;
  }

  &.exiting {
    animation: slide-out 250ms ease-out;
  }

  .division-line {
    background-color: $light-grey;
    height: 1.5px;
    border: none;
    margin: 16px 0;
  }

  .side-bar-content {
    padding: 60px 30px;

    .side-bar-logo-container {
      padding-bottom: 5px;

      .side-bar-logo {
        width: 90px;
      }

      .side-bar-school {
        font-size: 12px;
        color: $dark-grey;
        margin-top: 2px;
      }
    }

    .side-bar-nav {
      .side-bar-category-ul {
        .side-bar-nav-li {
          padding: 8px 0px;

          a {
            font-size: 17px;
          }
        }
      }
    }

    .side-bar-more {
      .side-bar-more-content {
        padding: 5px 0;
        font-size: 15px;
      }
    }
  }

  .side-bar-bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: $background-color;
    padding: 20px 30px 20px 30px;

    .side-bar-login-button {
      width: 100%;
      font-size: 15px;
      font-weight: 500;
      color: $dark-grey;
      display: flex;
      justify-content: space-between;

      .side-bar-login-button-arrow {
        top: 2px;
      }
    }
  }
}

.side-bar-overlay {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
  opacity: 1;

  &.entering {
    animation: opacity-in 250ms ease-in;
  }

  &.exiting {
    animation: opacity-out 250ms ease-out;
  }
}

@keyframes slide-in {
  from {
    left: -65vw;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    left: 0;
    opacity: 1;
  }
  to {
    left: -65vw;
    opacity: 0;
  }
}

@keyframes opacity-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
