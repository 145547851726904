@import "../../styles/common";

.application {
  &:hover {
    cursor: pointer;
  }

  display: flex;
  flex-direction: column;
  border: 1px solid $grey;
  background-color: white;
  border-radius: 18px;
  padding: 20px;
  aspect-ratio: 1 / 1;

  .application-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 1.8vw;

    .application-title-title {
      max-width: 80%;
      word-break: keep-all;
      font-weight: 500;
    }
  }

  .application-comment {
    margin: 8px 0;
    font-weight: 300;
    width: 100%;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;

    -webkit-line-clamp: 2;
  }

  .application-end-date {
    margin-top: auto;
    float: bottom;
    font-weight: 300;
    font-size: 1vw;
    text-align: right;
    white-space: nowrap;
  }

  &:hover {
    background-color: $light-grey;
  }
}
