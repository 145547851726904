@import "../../../styles/common";

.text {
  width: 100%;
  height: 24px;
  border: none;
  border-bottom: 1px solid $grey;
  background-color: transparent;
  padding: 0 11px 2px;
  font-size: 18px;
  color: $input-text;

  &::placeholder {
    color: $medium-grey;
  }
}
