@import "../../../styles/common";

.search {
  position: relative;
  display: inline-block;

  input[type="text"] {
    width: 100%;
    height: 34px;
    padding: 0 60px 0 14px;
    border: 1px solid $light-grey;
    border-radius: 6px;
    background-color: $light-grey;
    font-weight: 400;
    font-size: 12px;
    color: #666;
    line-height: 16px;
    outline: none;

    &::placeholder {
      color: #cccccc;
    }

    &:focus {
      background-color: white;
      color: #333;
    }
  }

  .search-delete {
    position: absolute;
    top: 0;
    right: 30px;
    width: 36px;
    height: 36px;
    border: 0;
    background: url(https://res.kurly.com/pc/ico/2010/ico_search_del.svg) no-repeat 50% 50%;
    background-size: 12px 12px;
  }

  .search-delete-show {
    opacity: 1;
  }

  .search-delete-no {
    opacity: 0;
  }

  .search-go {
    position: absolute;
    right: 5px;
    top: 3px;
    width: 30px;
    height: 30px;
  }
}
