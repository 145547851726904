@import "../../../styles/common";

.profile-popover-container {
  position: absolute;
  top: 65.5px;
  right: 0;
  width: 20vw;
  padding: 10px;
  border-radius: 15px;
  background-color: white;
  border: 1px solid $light-grey;
  box-shadow: 0 16px 20px rgba(173, 173, 173, 0.2);

  &.disabled {
    display: none;
  }

  .profile-popover {
    .profile-popover-list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      border-radius: 10px;
      color: $black;

      &:hover {
        background-color: $light-grey;
        cursor: pointer;
      }
    }
  }

  .profile-popover-ggoranji {
    position: absolute;
    right: 10%;
    bottom: 100%;
    height: 11px;
    overflow: hidden;
    margin-bottom: -1px;

    &::after {
      content: "";
      margin-top: 4px;
      border: 1px solid $light-grey;
      background-color: #fff;
      height: 14px;
      width: 14px;
      display: block;
      border-top-right-radius: 30%;
      -webkit-transform: rotate(-55deg) skewX(-20deg);
      transform: rotate(-55deg) skewX(-20deg);
    }
  }
}

@media screen and (max-width: 600px) {
  .profile-popover-container {
    border: 1px solid $grey;
    position: absolute;
    top: -25px;
    right: 10px;
    width: 25vw;
    .profile-popover {
      .profile-popover-list {
        font-size: 10px;
      }
    }
  }
}
