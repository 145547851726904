@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import url("https://cdn.jsdelivr.net/gh/toss/tossface/dist/tossface.css");
@import "styles/common";

:root {
  --vh: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: Spoqa Han Sans Neo, sans-serif;
}

body {
  background-color: #fafafa;
  color: black;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
}

ul {
  list-style: none;
}

li {
  display: inline-block;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: black;
  display: inline-block;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.ReactModalPortal {
  position: relative;
  z-index: 9999;

  .modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);

    .modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid rgb(204, 204, 204);
      opacity: 1;
      overflow: auto;
      outline: none;
    }
  }
}

.ReactModal__Body--open {
  overflow: hidden;
}

.section-header {
  margin-bottom: 30px;

  .section-header-title {
    font-size: 30px;
    font-weight: 600;
  }

  .section-header-description {
    font-size: 20px;
    font-weight: 400;
    color: $dark-grey;
  }
}

input[type="text"] {
  &:focus {
    outline: none;
  }
}

#root {
  height: 100%;

  .App {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 76px;

    section {
      flex: 1;
    }
  }
}

.App-disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;

  width: 100vw;
  height: 100vh;
  background-color: $background-color;
}
