@import "../../styles/common";

.button {
  background-color: $main-red;
  border-radius: 8px;

  span {
    color: white;
    font-weight: 500;
  }

  &.disabled {
    background-color: #D9D9D9;
  }
}
