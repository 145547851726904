@import "../../../../styles/common";

.result-search {
  position: relative;
  display: inline-block;

  &.focused {
    filter: drop-shadow(0px 1px 25px rgba(0, 0, 0, 0.1));

    .search {
      border-bottom: 1px solid $grey;

      input[type="text"] {
        border-radius: 6px 6px 0 0;
        border: none;
      }
    }
  }

  .search {
    width: 100%;
    z-index: 2;
  }

  .result {
    padding: 5px;
    border-radius: 0 0 10px 10px;
    background-color: white;
  }
}

.result-teacher {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 10px;
  border-radius: 10px;

  &:hover {
    background-color: #e7e7e7;
  }

  .profile-image {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  span {
    font-size: 13px;
    font-weight: 300;
  }
}

.result.no-result {
  padding: 10px;
  font-size: 13px;
  font-weight: 300;
  color: $black;
}
