@import "../../../styles/common.scss";
.banner-card{
  padding-bottom: 30px;
}
.banner-card-card {
  width: 38vw;
  background-color: white;
  position: relative;
  overflow: hidden; // img div 안에
  .banner-card-image {
    img {
      width: 100%;
    }
  }
  .banner-card-text {
    padding: 10px 20px;
    .banner-card-text-top {
      align-items: center;
      display: inline-block;
      .progress-icon {
        float: left;
        color: #66bb6a;
        border: 1px solid #66bb6a;
        padding: 3px 8px;
        border-radius: 30px;
        font-size: 15px;
      }
      .close-icon {
        color: $main-red;
        float: left;
        border: 1px solid $main-red;
        padding: 3px 8px;
        border-radius: 30px;
      }
      .banner-end-date {
        position: relative;
        left: 10px;
        font-size: 20px;
        display: inline-block;

        p {
          float: left;
          padding-right: 4px;
        }
      }
    }
    .banner-card-text-bottom {
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $medium-grey;
      p {
        font-size: 18px;
      }
      .banner-card-pen {
        font-size: 20px;
        color: $medium-grey;
      }
    }
  }
}
