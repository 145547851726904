@import "../../../styles/common";

.application-management {
  width: 75vw;
  margin: 0 auto;
  padding: 50px 10px;
  transition: all 0.6s;
  -webkit-transition: all 0.6s;

  &.half {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 75%;
    padding: 50px 40px;
    margin: 0;
  }

  .application-management-application-header {
    font-size: 40px;
    font-weight: 600;
    position: relative;

    .application-management-application-header-title {
      .emoji {
        margin-right: 6px;
      }
    }

    .application-management-application-header-description {
      font-weight: 300;
      font-size: 19px;
    }

    .application-management-application-header-time {
      font-weight: 300;
      font-size: 20px;
    }

    .application-management--export {
      width: 30px;
      height: 33px;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }
}
