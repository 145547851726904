@import "../../../styles/common";

.answer {

  .answer-box {
    width: 100%;

    .answer-box-answer {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;

      .answer-radio, .answer-check {
        display: inline-flex;
      }

      .answer-text {
        display: inline-block;
        font-size: 14px;
        width: 60%;
        padding: 0;
      }

      .cancel {
        width: 14px;
        height: 14px;
        margin-left: 10px;
      }
    }
  }

  .add-answer {
    padding: 7px;
    margin: 10px 0 0 10px;
    font-size: 12px;
    background-color: white;
    color: $medium-grey;
    border-radius: 8px;


    &:hover {
      background-color: $light-grey;
    }
  }
}

.question-text {
  padding: 10px 10px;
  font-size: 14px;
}
