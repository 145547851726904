@import "../../../styles/common";

.application-detail-application {
  position: relative;
  width: 75vw;
  max-width: 750px;
  margin: 0 auto;
  padding: 50px 10px;
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  word-break: keep-all;

  &.half {
    max-width: none;
    width: 75vw;
  }

  .application-detail-application-header {
    font-size: 30px;
    font-weight: 600;

    .application-detail-application-header-title {
      .emoji {
        margin-right: 6px;
      }
    }

    .application-detail-application-header-description {
      font-weight: 300;
      font-size: 20px;
    }

    .application-detail-application-header-time {
      font-weight: 300;
      font-size: 20px;
    }
  }

  .application-detail-application-section {
    margin: 30px 0;
    flex: 1;
    overflow-y: scroll;
  }

  .application-detail-application-submit {
    width: 100%;
    height: 40px;

    span {
      font-size: 20px;
    }
  }
}


@media screen and (min-width: 590px) {
  .half {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 75vw;
    padding: 50px 100px;
    margin: 0;
  }
}

@media screen and (max-width: 1160px) {
  .application-detail-application {
    width: 65vw;

    &.half {
      width: 65vw;
      padding: 50px;
    }
  }
}

@media screen and (max-width: 600px) {
  .application-detail-application {
    transition: none;
    -webkit-transition: none;
    width: 80vw;
    margin: 0 auto;
    padding: 50px 0;
  }
}
