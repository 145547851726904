@import "../../../styles/common";

.application-management--result-table {
  text-align: center;
  width: 100%;
  margin-top: 30px;
  border-collapse: separate;
  border-spacing: 0;

  .application-management--result-table--content-empty {
    padding: 50px 0;
    color: $medium-grey;
  }

  .application-management--result-table--link {
    background: url("https://simblue-client.vercel.app/images/link.svg") no-repeat 0 0;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    content: '';
    display: inline-block;
  }

  .application-management--result-table--field {
    font-weight: 500;

    td {
      border-top: 1px solid $grey;
      border-bottom: 1px solid $grey;
    }

    td:first-child {
      border-top-left-radius: 10px;
      border-left: 1px solid $grey;
    }

    td:last-child {
      border-top-right-radius: 10px;
      border-right: 1px solid $grey;
    }
  }

  tbody {
    font-weight: 400;

    tr:first-child {
      td {
        border-top: 1px solid $grey;
      }
    }

    tr:last-child {
      td {
        border-bottom: 1px solid $grey;
      }
    }

    tr {
      td:first-child {
        border-left: 1px solid $grey;
      }

      td:last-child {
        border-right: 1px solid $grey;
      }
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 10px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 10px;
    }
  }

  tbody:before {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
  }

  td {
    padding: 15px 15px;
    background-color: #fff;
    border: 1px solid #fff;
    word-break: keep-all;
  }
}
