@import "../../../styles/common";

.record-kanban {
  background-color: $light-grey;
  width: 24%;
  height: auto;
  min-height: 50vh;
  padding: 10px 15px;
  border-radius: 20px;

  .record-kanban-top {
    span {
      font-size: 20px;
    }

    .record-kanban-title {
      padding-left: 5px;
    }
  }

  .record-kanban-bottom {
    width: 100%;
  }
}
