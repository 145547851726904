@import "../../styles/common.scss";

.groupmanage {
  width: 80vw;
  min-width: 350px;
  margin: 0 auto;
  padding: 80px 10px;
  .section-header {
    margin-bottom: 15px;
  }
  .group-mange-create {
    text-align: right;
    margin-bottom: 15px;
    p {
        display: inline-block;
        color: $dark-grey;
        &:hover{
            cursor: pointer;
        }
    }
  }
  .group-manage-bottom{
    display: flex;
    flex-wrap: wrap;
    gap: 1vw 2.8%;
    width: 100%;
  }
}
