@import "../../styles/common";

.notice {
  width: 100%;
  position: relative;
  background-color: white;
  border-radius: 15px;
  padding: 9% 7%;
  margin-bottom: 20px;
  border: 1px solid $grey;
  z-index: 2;

  .notice-text {
    font-size: 24px;
    margin-bottom: 4px;
    word-break: keep-all;
  }

  .notice-info {
    font-size: 14px;
    font-weight: 300;
  }

  .pin {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    opacity: 1;

    &.not-pinned {
      opacity: 0;
    }
  }

  .edit {
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}
