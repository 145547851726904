$main-red: #F36E67;
$medium-grey: #C2C2C2;
$light-grey: #F6F6F6;
$grey: #E6E6E6;
$background-color: #FAFAFA;
$main-beige: #FCF9F2;
$dark-grey: #707070;
$main-turquoise: #233A26;
$input-text: #424242;
$black: #484848;
