@import "../../../styles/common";

.advanced-setting-modal {
  width: 60vw;
  height: 60vh;
  border-radius: 15px;
  background-color: $background-color;

  .left {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 50%;
    height: 100%;
    float: left;
    background-color: $light-grey;
    padding: 50px 40px;

    .title {
      height: 10%;
      font-size: 25px;
      font-weight: 500;
    }

    .setting-content {
      height: 90%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .application-delete-button {
        width: 100%;
        height: 45px;
        border-radius: 8px;
        background-color: white;
        border: 1px solid $medium-grey;

        span {
          color: $medium-grey;
          font-size: 23px;
        }
      }
    }
  }

  .right {
    width: 50%;
    height: 100%;
    float: right;
    padding: 50px 40px 0 40px;
    display: flex;
    flex-direction: column;

    .sub-title {
      color: $black;
      font-size: 20px;
      font-weight: 500;
      padding-bottom: 20px;
    }

    .search-owner {
      width: 100%;
    }

    .owner-list {
      flex: 1 1 0;
      margin-top: 30px;
      padding-bottom: 30px;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
