@import "../../../styles/common.scss";

.manage-banner-modal {
  width: 700px;
  height: 600px;
  padding: 36px;
  background-color: $background-color;
  border-radius: 12px;

  .manage-banner-modal-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .manage-banner-modal-wrap-textbox {
      display: flex;
      flex-direction: column;
      gap: 18px;

      .manage-banner-modal-header {
        display: flex;
        justify-content: space-between;

        .manage-banner-text-title {
          color: black;
          font-weight: 600;
          font-size: 1.5rem;
          line-height: 140%;
          letter-spacing: 0.15px;
        }

        .register-modal-close {
          font-size: 25px;
        }
      }

      .manage-banner-text-image {
        .manage-banner-text-image-annae {
          align-items: center;
          display: flex;
          justify-content: space-between;

          .manage-banner-text-image-left {
            p {
              float: left;
            }
          }

          padding-bottom: 5px;
        }

        .manage-banner-image-space {
          border-radius: 9px;
          overflow: hidden;
          margin: 0 auto;

          .banner-profileImg-label {
            cursor: pointer;
            color: $medium-grey;

            .form-banner {
              height: 180px;
              display: flex;
              align-content: center;
              justify-content: center;
              flex-wrap: wrap;
              border-radius: 9px;

              &.border {
                border: 3px dashed $medium-grey;
              }

              img {
                width: 100%;
                height: 100%;
              }

              .banner-upload-icon {
              }
            }
          }

          .banner-profileImg-input {
            border: none;
            display: none;
          }
        }

        .form-banner input[type="file"] {
          display: none;
        }
      }
    }

    .banner-register-end-date {
      padding-top: 10px;

      .banner-register-end-date-text {
        align-items: center;
        width: 39%;
        display: flex;
        justify-content: space-between;

        .banner-register-end-date-left {
          p {
            float: left;
          }
        }
      }

      .banner-register-end-date-input {
        padding-top: 5px;
      }
    }

    .banner-register-link {
      padding-top: 5px;

      .banner-register-link-text {
        align-items: center;
        width: 34%;
        display: flex;
        justify-content: space-between;
      }

      .banner-register-link-input {
        padding-top: 5px;
      }
    }
  }

  .manage-banner-modal-wrap-buttonbox {
    display: flex;
    gap: 16px;
    padding-top: 10px;

    button {
      width: 100%;
      font-size: 15px;
      font-weight: 500;
      padding: 11px 10px;
    }

    &.update {
      button {
        width: 50%;
      }

      .delete-button {
        background-color: white;
        border: 1px solid $medium-grey;

        span {
          color: $dark-grey;
        }
      }
    }
  }
}

.banner-register-input {
  padding: 10px 15px;
  width: 100%;
  border: 1px solid $grey;
  border-radius: 10px;
  background-color: white;

  &::placeholder {
    color: $medium-grey;
  }
}

.banner-register-explain {
  color: $dark-grey;
  font-size: 11px;
}
