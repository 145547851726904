@import '../../styles/common';

.form-section {
  width: 70vw;
  margin: 0 auto;

  .form-header {
    padding: 15px 12px;

    .form-header-top {
      width: 100%;
      height: 80px;

      .form-header-left {
        display: flex;
        align-items: center;
        width: 50%;
        height: 100%;
        float: left;

        .form-header-left-emoji {
          position: relative;

          .form-header-left-emoji-input {
            background-color: $grey;
            font-size: 50px;
            width: 80px;
            padding: 10px;
            border-radius: 10px;
            display: inline-block;
            height: 100%;
            border: none;
          }
        }

        .form-header-left-title {
          display: inline-block;
          width: 78%;
          height: 100%;
          font-size: 40px;
          font-weight: bold;
          margin-left: 10px;
          border-bottom-color: $medium-grey;
        }

      }

      .form-header-right-date-wrapper {
        width: 50%;
        height: 80px;
        float: right;
        text-align: right;
        display: inline-flex;
        align-items: center;
        justify-content: right;

        .form-header-right-date {
          span {
            color: $medium-grey;
            font-size: 16px;
            margin-right: 10px;
          }

          .form-header-right-date-top {
            display: inline-flex;
            align-items: center;

            .always-label {
              margin-left: 10px;
              display: inline-flex;

              span {
                margin-right: 0;
              }
            }

            .always-button {
              width: 18px;
              height: 18px;
              margin-right: 3px;
            }
          }

          .form-header-right-date-bottom {
            margin-top: 8px;
          }
        }
      }
    }

    .form-header-description {
      margin-top: 15px;
      border-bottom-color: $medium-grey;

    }
  }

  .form-question-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
  }

  .add-question-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 50px;
    border-radius: 15px;
    border: 2px solid $grey;
    margin-bottom: 100px;
    background-color: transparent;

    span {
      font-size: 50px;
      line-height: 50px;
      font-weight: 500;
      color: $medium-grey;
    }

    &:hover {
      background-color: $light-grey;
    }
  }

  .button-area {
    width: 70vw;
    position: fixed;
    left: 50%;
    bottom: 15px;
    transform: translate(-50%, 0);

    .button {
      font-size: 16px;
      padding: 13px 0;
    }

    .advanced-setting-button {
      width: 19%;
      margin-right: 2%;
      background-color: white;
      border: 1px solid $medium-grey;

      span {
        color: $medium-grey;
      }
    }

    .form-button {
      width: 79%;
    }
  }
}

.__EmojiPicker__.epr-emoji-native {
  font-family: Tossface, sans-serif !important;
}

.EmojiPickerReact {
  position: absolute !important;
  top: 82.5px;
  z-index: 2;

  --epr-bg-color: rgba(255, 255, 255, 0.7) !important;
}

.epr-emoji-list li {
  display: block !important;
}
