@import '../../../styles/common';

.question {
  box-shadow: 0 16px 40px rgba(173, 173, 173, 0.2);
  width: 50vw;

  background-color: white;
  border-radius: 20px;
  padding: 30px 35px;
  margin-bottom: 30px;

  .question-header {
    width: 100%;

    .question-header-question-type {
      border: 1px solid $medium-grey;
      padding: 10px 20px;
      font-size: 15px;
      color: $medium-grey;
      border-radius: 4px;
      float: right;
      width: 25%;
      height: 100%;

      &:focus {
        outline: none;
      }
    }

    .question-header-question {
      float: left;
      font-size: 20px;
      width: 70%;
      height: 100%;
      padding: 10px 20px;
      background-color: $main-beige;
      border: none;
      border-bottom: 1px solid $grey;

      &::placeholder {
        color: $medium-grey;
      }
    }

    .question-header-description {
      padding: 10px 10px;
      font-size: 14px;
      margin-top: 7px;
    }

  }

  .QuestionDiv-answer {
    width: 100%;
    margin-top: 30px;
  }

  .question-footer {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;

    .action-question-container {
      display: flex;
      gap: 5px;

      .action-question {
        display: inline-block;
        width: 30px;
        cursor: pointer;
      }
    }
  }
}
