@import "../../../../styles/common";

.application-questions {
  .application-item {
    display: flex;
    align-items: flex-start;
    padding-bottom: 35px;

    .application-questions-question {
      display: inline-block;
      width: 100%;

      font-size: 18px;
      font-weight: 500;
      word-break: keep-all;
    }

    .application-questions-answer {
      display: inline-flex;
      flex-wrap: wrap;
      gap: 11px 25px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 590px) {
  .application-questions {
    .application-item {
      .application-questions-answer {
        width: 100%;
      }
      .application-questions-column {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
      }
    }
  }
}
