@import "../../styles/common";

.look {
  width: 70vw;
  margin: 0 auto;
  padding: 50px 10px;

  .look-category-section {
    display: flex;
    gap: 20px;
    padding-bottom: 20px;

    .look-category-section-category {
      display: inline-block;
      font-size: 20px;
      cursor: pointer;

      &.selected {
        font-weight: 600;
        color: $main-red;
        border-bottom: 2px solid $main-red;
      }
    }
  }

  .look-application-section {
    display: flex;
    flex-wrap: wrap;
    gap: 1.3vw 1.3%;
    width: 100%;

    a {
      width: 24.025%;

      .application {
        width: 100%;
        height: 100%;
      }
    }
  }

  .none {
    color: $dark-grey;
    text-align: center;
    padding-top: 50px;
  }
}

@media screen and (max-width: 1000px) {
  .look > .look-application-section > a {
    width: 32.4%;

    .application {
      .application-title {
        .application-title-title {
          font-size: 2vw;
        }

        .application-comment {
          font-size: 15px;
        }

        .emoji {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .look {
    width: 80vw;

    .look-application-section > a {
      width: 48.7%;

      .application {
        .application-title {
          .application-title-title {
            font-size: 3.5vw;
          }
        }

        .application-comment {
          font-size: 14px;
        }

        .application-end-date {
          font-size: 12px;
        }
      }
    }
  }
}
